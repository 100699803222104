import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button, Modal, Row, Col, Tooltip, Typography, Table } from "antd";
import { CheckOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment-jalaali";

import { ENV } from "scripts/settings";
import { GetUrlParams, ObjectToURL, AddNotification } from "scripts/helpers";
import api from "scripts/api";
import paths from "scripts/paths";
import resources from "scripts/resources";

import VideoCover from "images/video-cover.jpg";

const Component = ({ permissions, setRoute }) => {
	const { Title } = Typography;

	const initialState = { data: [], pagination: {} };
	const tableColumns = [
		{
			title: resources.row,
			dataIndex: "index",
			className: "center-cell",
			width: "60px",
			render: (value, record, index) => (pagination.current - 1) * pagination.pageSize + index + 1,
		},
		Table.EXPAND_COLUMN,
		{
			title: resources.date,
			dataIndex: "date",
			className: "center-cell desc-cell ltr-cell",
			width: "100px",
			render: (value) => {
				const [date, time] = moment(value).format(process.env.REACT_APP_DATE_TIME_FORMAT_JALALI).split(" ");

				return (
					<>
						<span className="name">{date}</span>
					</>
				);
			},
		},
		{
			title: resources.title,
			dataIndex: "title",
			className: "center-cell",
		},
		{
			title: resources.action,
			dataIndex: "uris",
			className: "center-cell",
			width: "120px",
			render: (value) =>
				value.length == 0
					? `-`
					: value.map((item) => {
							console.log("items items ", item);

							return (
								// <Image
								// 	src={`${ENV.basePath}${item}`}
								// 	// shape="square"
								// 	width={40}
								// 	height={40}
								// />
								<Tooltip title={resources.view}>
									<Button
										type="link"
										icon={<EyeOutlined />}
										href={item}
										target="_blank"
										onClick={() => window.open(`${ENV.basePath}${item}`, "_blank")}
									/>
								</Tooltip>
							);
					  }),
		},
	];
	const [state, setState] = useState(initialState);
	const { data, loading, pagination, selected } = state;
	const { pathname, search } = useLocation();
	const [modal, contextHolder] = Modal.useModal();
	const navigate = useNavigate();

	const fetchData = () => {
		AddNotification();
		const params = GetUrlParams(search);

		setState((prevState) => ({ ...prevState, loading: true }));

		api.stPanel
			.getCours({ ...params, pageSize: process.env.REACT_APP_PAGE_SIZE })
			.then(({ list: data, pagination }) => {
				setState((prevState) => ({ ...prevState, data, pagination, loading: false }));
			})
			.catch(() => {
				setState((prevState) => ({ ...prevState, loading: false }));
			});
	};

	const handleDelete = (id) => {
		modal.confirm({
			title: resources.deleteConfirm,
			okText: resources.yes,
			okType: "danger",
			cancelText: resources.no,
			onOk: () => {
				setState((prevState) => ({ ...prevState, loading: true }));

				api.disRefer
					.delete(id)
					.then(() => {
						fetchData();
					})
					.catch(() => {
						setState((prevState) => ({ ...prevState, loading: false }));
					});
			},
		});
	};
	const handleTable = ({ current }) => {
		const params = GetUrlParams(search);
		const query = ObjectToURL({ ...params, page: current });

		navigate(`${pathname}?${query}`);
	};

	const componentDidUpdate = () => {
		fetchData();
	};

	const componentDidMount = () => {
		setRoute();
	};

	useEffect(componentDidUpdate, [search]);
	useEffect(componentDidMount, []);

	if (permissions.remove) {
		tableColumns.push({
			title: resources.action,
			dataIndex: "id",
			className: "center-cell",
			width: "120px",
			render: (value) => (
				<Tooltip title={resources.delete}>
					<Button
						type="link"
						icon={<DeleteOutlined />}
						onClick={() => handleDelete(value)}
					/>
				</Tooltip>
			),
		});
	}

	return (
		<>
			{/* <Tabs
				className="sp-tabs"
				activeKey={2}
				items={[
					{ key: 1, label: <Link to={paths.refer.educational}>{`${resources.education}${resources.y}`}</Link> },
					{ key: 2, label: <Link to={paths.refer.disciplinary}>{`${resources.discipline}${resources.y}`}</Link> },
				]}
			/> */}
			<Table
				columns={tableColumns}
				dataSource={data}
				loading={loading}
				onChange={handleTable}
				pagination={pagination}
				scroll={{ x: 930 }}
				size="small"
				rowKey="id"
			/>
			{contextHolder}
		</>
	);
};

export default Component;
